import {ISemiAnnualReport} from "../../../../../models/SemiAnnualReport/SemiAnnualReport";
import React, {FunctionComponent, useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import FileDragAndDrop from "../../../../../components/form/FileDragAndDrop";
import {RootStoreContext} from "../../../../../stores/RootStore";
import Button from "../../../../../components/generic/Button";
import {AttachmentFormValues, IAttachment} from "../../../../../models/generic/Attachment";

import {useAttachments} from "../hooks/useAttachments";
import LegalAgreementInput from "../forms/LegalAgreementInput";
import {CheckIcon, CloudArrowDownIcon, XMarkIcon} from "@heroicons/react/20/solid";
import JSONView from "../../../../../components/generic/JSONView";

interface OwnProps {
    semiAnnualReport: ISemiAnnualReport,
    onSubmit: (submitAttachment?: IAttachment) => Promise<void> | void
}

type Props = OwnProps;

const SemiAnnualReportSignerForm: FunctionComponent<Props> = ({semiAnnualReport, onSubmit}) => {
    const rootStore = useContext(RootStoreContext);
    const {user, hasRole, isRLUser, isFCC} = rootStore.oidcStore;
    const {closeModal} = rootStore.modalStore;

    const [confirmAgreement, setConfirmAgreement] = useState(false);
    const [currentAcknowledge, setCurrentAcknowledge] = useState({name: user?.profile.name, title: ''})
    const [currentAttachment, setCurrentAttachment] = useState(new AttachmentFormValues());

    const {downloadSnapshotAttachment} = useAttachments(semiAnnualReport)

    const handleChange = (e) => {
        const {value, checked, name} = e.currentTarget;

        if (name === 'Acknowledge')
            setConfirmAgreement(!!checked)
        else handleAcknowledgeInput(value, name)
    }

    const handleAcknowledgeInput = (value, name) => {
        setCurrentAcknowledge(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let tmpAttachment = {
            ...currentAttachment,
            submitterName: currentAcknowledge.name,
            submitterTitle: currentAcknowledge.title
        }
        await onSubmit(tmpAttachment);
        await closeModal('SemiAnnualReportSignerModal')
    }

    const handleDownloadReport = async (e) => {
        e.preventDefault()
        await downloadSnapshotAttachment()
    }

    const handleFile = (files) => {
        setCurrentAttachment(prev => {
            return {...prev, file: files[0]}
        })
    }

    const canSave = () => {
        if (hasRole('signing')) return currentAcknowledge.name && currentAcknowledge.title && confirmAgreement
        if (!hasRole('signing')) return currentAttachment.file

    }

    return (<form autoComplete={'off'} onSubmit={handleSubmit}
                  className={'overflow-y-auto overflow-x-hidden flex flex-col h-full'}>
        <div className={'flex-1'}>
            <div className='mb-3'>
                <b>Entity State:</b> <span>{semiAnnualReport.entityState}</span> <br/>
                <b>Last Day to File:</b> <span>{semiAnnualReport.lastDayToFile}</span>
            </div>


            {/* Entity Reviewed */}
            {(isFCC || isRLUser) && semiAnnualReport.currentStatus === 'EntityReviewed' && <>
                    <div className={'mb-3'}>
                        <b>Submitter Name:</b> <span>{semiAnnualReport.submitterName}</span> <br/>
                        <b>Submitter Title:</b> <span>{semiAnnualReport.submitterTitle}</span>
                    </div>

                    <div className={'mb-3'}>
                        <Button onClick={handleDownloadReport} className={'gap-2'}
                                title={'Downloads the Semi-Annual Report (PDF).'}>
                            <CloudArrowDownIcon className={'h-5 w-5 inline-block'}/>
                            Download SAR PDF
                        </Button>
                    </div>
                </>}


            {/* Initiated */}
            <div className={'mb-3'}>
                <LegalAgreementInput name={'Acknowledge'} onChange={handleChange}
                                     acknowledge={currentAcknowledge} checked={confirmAgreement}/>
            </div>

            {!hasRole('signing') && <div>
                <FileDragAndDrop
                    onChange={handleFile}
                    acceptedFileTypes={'.pdf'}
                    maxNumberOfFiles={1}
                    required/>
            </div>}
        </div>

        <JSONView data={currentAcknowledge} title={'currentAcknowledge'}/>
        <JSONView data={currentAttachment} title={'currentAttachment'}/>
        <JSONView data={confirmAgreement} title={'confirmAgreement'}/>


        <div className={'flex justify-end'}>
            <Button iconLeft={<CheckIcon className={'h-5 w-5'}/>} name={'save'} color={'green'} type={'submit'}
                    disabled={!canSave()}>Save</Button>
            <span className={'m-2'}/>
            <Button name={'cancel'} onClick={() => closeModal('SemiAnnualReportSignerModal')} color="red"
                    iconLeft={<XMarkIcon className={'h-5 w-5'}/>}>Cancel</Button>
        </div>
    </form>)
}

export default observer(SemiAnnualReportSignerForm);